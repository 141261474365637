import classnames from "classnames";
import { isLeft } from "fp-ts/Either";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { RemoteList } from "../../components/remote/RemoteList.component";
import { FeedBlockValueResolver } from "@ihr-radioedit/inferno-core";
import { isCalendarFeedBlock } from "../../lib/guards";
import { getPaths } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { lookup, removePrefix } from "@ihr-radioedit/inferno-core";
import { isFeedBlock, reverseRoute } from "@inferno/renderer-shared-core";
import { getDatasourceBySlug } from "@ihr-radioedit/inferno-core";
import { getDisplayHints, isPodcastFeed } from "../../services/Sites.utils";
import { Container } from "../../ui";
import CalendarDatasourceTile from "./CalendarDatasourceTile.component";
import { DatasourceLoaderProps } from "./DatasourceLoader.component";
import DatasourceTile from "./DatasourceTile.component";

const log = ILog.logger("DatasourceTileLoader.component.tsx");

export const DatasourceTileLoader = inject("store")(
  observer(({ store, block, isPrimary, topic_title }: DatasourceLoaderProps) => {
    if (!store) {
      return null;
    }
    const { site, cache, microsite } = store;
    const displayHints = getDisplayHints(block.tags || []);
    const { hints } = displayHints;

    if (!isFeedBlock(block)) {
      return null;
    }

    // Decode block value
    const decoded = FeedBlockValueResolver.decode(block.value);
    if (isLeft(decoded)) {
      log.error("Could not decode feed block", getPaths(decoded), block);
      return null;
    }
    const { right: blockValue } = decoded;

    const feedId = blockValue.feed_id;

    if (!feedId) {
      log.error("No feed id or default feed setup!", block);
      return null;
    }

    // Hide if there are no results
    if (!block.feed?.results.length) {
      return null;
    }

    // Fetch all required data
    const feedCursor = getDatasourceBySlug(
      cache,
      { ...block.feed },
      feedId,
      block.id,
    )({
      slug: microsite?.index?.slug || site.index.slug,
      lookup,
    });

    const feedClass = classnames("component-feed", "tile", {
      [`feed-style-${displayHints.style}`]: displayHints.style,
      "podcasts-feed": isPodcastFeed(block.tags || []),
      "themed-block": hints?.includes("display-hints/themed-block"),
    });

    // Call store block for tagging
    useEffect(() => {
      if (block?.feed) {
        store.storeBlock({
          ...block,
          resolved: {
            value: blockValue,
            feed: block.feed,
            topic_title: topic_title || "",
          },
        });
      }
    });

    return (
      <Container className={feedClass} data-feed={feedId}>
        <RemoteList
          cursor={feedCursor}
          showLoading={isPrimary}
          fallback={
            <DatasourceTile
              title={blockValue.title || ""}
              content={block.feed.results}
              block={block}
              feedId={feedId}
              displayHints={displayHints}
            />
          }
        >
          {({ data, cursor }) => {
            if (isCalendarFeedBlock(block)) {
              return (
                <CalendarDatasourceTile
                  title={blockValue.title || ""}
                  content={data}
                  block={block}
                  feedId={feedId}
                  displayHints={displayHints}
                  url={reverseRoute(store.site, "calendar_content_all") ?? ""}
                />
              );
            }

            let url = "";
            const { resume } = cursor?.options();
            if (resume) {
              const { "<topic>": topic } = resume.context;
              const { from } = resume;
              if (topic && from) {
                url =
                  reverseRoute(store?.site, "topic_load_more", {
                    topic: removePrefix(/^\w+\//)(topic),
                    from,
                  }) ?? "#";
              }
            }

            return (
              <DatasourceTile
                title={blockValue.title || ""}
                content={data}
                block={block}
                feedId={feedId}
                displayHints={displayHints}
                url={url}
              />
            );
          }}
        </RemoteList>
      </Container>
    );
  }),
);

export default DatasourceTileLoader;
