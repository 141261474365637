import { inject, observer } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";
import { useTranslation } from "react-i18next";
import { Container } from "../../ui";
interface NoFeedItemProps {
  store?: Store;
}

export const NoFeedItemMessage = inject("store")(
  observer(({ store }: NoFeedItemProps) => {
    const { t } = useTranslation();
    const MicrositeName = store?.microsite?.sections?.general?.name;

    return (
      <Container>
        <p>{MicrositeName ? t("no_articles_on_microsite_page", { MicrositeName }) : t("no_articles_on_topic_page")}</p>
      </Container>
    );
  }),
);
