import { CalendarPayloadFields } from "@ihr-radioedit/inferno-core";

export const getEventStartDate = (fields: CalendarPayloadFields): number | null | undefined => {
  if (fields) {
    const { event_date } = fields;
    if (!!event_date?.value.length) {
      return event_date.value[0]?.begin;
    }
  }
  return null;
};

export const getEventEndDate = (fields: CalendarPayloadFields): number | null | undefined => {
  if (fields) {
    const { event_date } = fields;
    if (!!event_date?.value.length) {
      return event_date.value[0]?.end;
    }
  }
  return null;
};

export const getEventVenueName = ({ venue_name }: CalendarPayloadFields): string | null | undefined =>
  venue_name?.value ?? null;

export const getEventVenueAddress = ({ venue_address }: CalendarPayloadFields): string | null | undefined =>
  venue_address?.value ?? null;
