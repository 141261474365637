import { inject, observer } from "mobx-react";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Heading } from "../../ui";
import { HeadingLink, HeadingProps } from "../../ui/Heading.component";
import { MagicLink } from "@inferno/renderer-shared-ui";
import { DatasourceFeedItem, DatasourceProps } from "./Datasource.component";

export interface DatasourceTileProps extends DatasourceProps {
  url?: string;
}

export const DatasourceTile = inject("store")(
  observer((props: DatasourceTileProps) => {
    const { store, title, content, feedId, displayHints, block, url = "#" } = props;
    const { t } = useTranslation();

    if (!store || !content?.length) {
      return null;
    }

    const heading: HeadingProps = useMemo(() => props.heading ?? { level: 2 }, [props.heading]);
    const { showEyebrow } = displayHints;
    const header = useMemo(() => {
      const labelTitle = title ? ` ${title}` : "";
      const link: HeadingLink = {
        text: t("view_more"),
        label: `${t("view_more")}${labelTitle}`,
        url,
      };
      return title ? (
        <Heading {...heading} link={link}>
          <MagicLink to={url} sectionName="articles" context="view_more">
            {title}
          </MagicLink>
        </Heading>
      ) : null;
    }, [title, heading, url, t]);

    return (
      <Fragment key={block.id}>
        {header}
        <div className="feed-cards" data-block-id={block.id} suppressHydrationWarning={true}>
          <Grid columns={4}>
            {content.map((item, i) => {
              return (
                <DatasourceFeedItem
                  key={`feed-item-${feedId}-${(item as any).ref_id}-${i}`}
                  item={item}
                  displayHints={displayHints}
                  index={i}
                  showEyebrow={showEyebrow}
                  microsite={store.microsite}
                />
              );
            })}
          </Grid>
        </div>
      </Fragment>
    );
  }),
);

export default DatasourceTile;
