import loadable from "@loadable/component";
import { inject, observer } from "mobx-react";
import { Fragment, useMemo } from "react";
import {
  blockHaveAd,
  isCalendarPublishRecord,
  isFeedResult,
  isLeadsCard,
  isPublishRecord,
  showLoadMoreAd,
  Store,
} from "@inferno/renderer-shared-core";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { FeedResultFragment, SitesQueryDatasource } from "@ihr-radioedit/inferno-webapi";
import { FeedDisplayHints } from "../../services/Sites.utils";
import { Grid, Heading } from "../../ui";
import { HeadingProps } from "../../ui/Heading.component";
import { AdPosition } from "../Ad.component";
import "./Datasource.style.scss";
import { Microsite } from "@ihr-radioedit/inferno-core";
import { NoFeedItemMessage } from "./NoFeedItemMessage.component";
import { getEventStartDate } from "../../lib/calendar";
import { getLayout } from "../../lib/content";

const LeadFeedItem = loadable(() => import("./LeadFeedItem.component"));
const ContentFeedItem = loadable(() => import("./ContentFeedItem.component"));

export interface DatasourceProps {
  content: FeedResultFragment[] | null;
  block: BlockFragment;
  feedId: string;
  displayHints: FeedDisplayHints;
  store?: Store;
  title: string;
  heading?: HeadingProps;
}

export const DatasourceFeedItem = ({
  item,
  index,
  showEyebrow,
  displayHints,
  showDateStamp,
  microsite,
}: {
  item: FeedResultFragment;
  index: number;
  showEyebrow: boolean;
  displayHints: FeedDisplayHints;
  showDateStamp?: boolean;
  microsite: Microsite | undefined;
}) => {
  const { style } = displayHints;
  if (!isFeedResult(item)) {
    return null;
  }

  switch (item.type) {
    case SitesQueryDatasource.Publishing:
      if (isCalendarPublishRecord(item.record)) {
        return (
          <ContentFeedItem
            item={item.record}
            index={index}
            showEyebrow={showEyebrow}
            showDateStamp={showDateStamp}
            datetime={getEventStartDate(item.record.payload.fields) ?? item.record.pub_start}
            layout={getLayout(style)}
          />
        );
      }

      if (isPublishRecord(item.record)) {
        return (
          <ContentFeedItem
            item={item.record}
            index={index}
            showEyebrow={showEyebrow}
            showDateStamp={showDateStamp}
            datetime={item.record.pub_start}
            layout={getLayout(style, microsite)}
          />
        );
      }

      return null;
    case SitesQueryDatasource.Leads:
      return isLeadsCard(item.record) ? (
        <LeadFeedItem displayHints={displayHints} lead={item.record} index={index} />
      ) : null;
    default:
      return null;
  }
};

export const Datasource = inject("store")(
  observer((props: DatasourceProps) => {
    const { title, content, feedId, block, displayHints, store } = props;
    if (!content?.length) {
      if (props.displayHints.hints?.includes("display-hints/message-for-no-results")) {
        return <NoFeedItemMessage />;
      }
      return null;
    }

    const heading: HeadingProps = useMemo(() => props.heading ?? { level: 2 }, [props.heading]);
    const adBlockData = useMemo(() => ({ value: { ...block.value } } as BlockFragment), [block.value]);
    const { showEyebrow, enableLoadMore, showDateStamp } = displayHints;
    const adInsertPosition = useMemo(() => block.value.size || 10, [block.value.size]);
    const haveAds = useMemo(() => blockHaveAd(block.value.position), [block.value.position]);

    const header = useMemo(() => (title ? <Heading {...heading}>{title}</Heading> : null), [title, heading]);

    const items = useMemo(
      () =>
        content.map((item, i) => {
          const showAd = showLoadMoreAd(content.length, adInsertPosition, i, enableLoadMore);
          return (
            <Fragment key={`feed-item-${feedId}-${(item as any).ref_id}-${i}`}>
              <DatasourceFeedItem
                item={item}
                displayHints={displayHints}
                index={i}
                showEyebrow={showEyebrow}
                showDateStamp={showDateStamp}
                microsite={store?.microsite}
              />
              {haveAds && showAd ? (
                <div className="load-more-ad">
                  <AdPosition block={adBlockData} batchId={`${feedId}-${Math.ceil(i / adInsertPosition)}`} />
                </div>
              ) : null}
            </Fragment>
          );
        }),
      [
        adBlockData,
        adInsertPosition,
        content,
        displayHints,
        enableLoadMore,
        feedId,
        haveAds,
        showEyebrow,
        showDateStamp,
        store?.microsite,
      ],
    );

    return (
      <Fragment key={block.id}>
        {header}
        <div className="feed-cards" data-block-id={block.id} suppressHydrationWarning={true}>
          <Grid columns={displayHints.columns}>{items}</Grid>
        </div>
      </Fragment>
    );
  }),
);

export default Datasource;
